<template>
    <DataTableCRUD
        v-model="value"
        :title=title
        :headers=headers
        itemName="Category"
        :defaultItem=defaultItem
        v-on:saveItem="saveItem"
        v-on:deleteItem="deleteItem"
    >
        <template v-slot:form="slotProps">
            <v-container>
                <v-row>
                    <!-- TODO: NEED rules / validation for these form inputs!! -->
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                        <v-text-field
                            v-model="slotProps.editedItem.name"
                            label="Name"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                        <v-text-field
                            v-model="slotProps.editedItem.amount"
                            label="Amount"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                        <v-text-field
                            v-model="slotProps.editedItem.description"
                            label="Description"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </template>

        <template v-slot:deleteTitle="slotProps">
            <div style="text-align:center; width:100%;">
                Are you sure you want to delete<br>"{{ slotProps.editedItem.name }}"?
            </div>
        </template>
    </DataTableCRUD>
</template>

<script>
import DataTableCRUD from '@/components/DataTableCRUD.vue';
import {Getter as ProfileGetter} from '@/store/profile/types'
import {Action as BudgetCategoryAction} from '@/store/budgetCategory/types'

export default {
    name: "CategoryInputList",
    components: {
        DataTableCRUD,
    },
    data: () => ({
        headers: [
            {
                text: 'Name',
                align: 'start',
                sortable: false,
                value: 'name'
            },
            {
                text: 'Amount',
                value: 'amount'
            },
            {
                text: "Actions",
                value: 'actions'
            },
        ],
    }),
    methods: {
        saveItem: function(event) {
            console.log(event)
            if (!event.item.finpicid) {
                event.item.finpicid = this.finpicid
            }
            if (!event.item.instanceid) {
                event.item.instanceid = this.instanceid
            }
            if (!event.item.budgetid) {
                event.item.budgetid = this.budgetid
            }
            this.$store.dispatch(
                `${[BudgetCategoryAction.UpsertMonthlyCategoryInstance]}`,
                event.item
                ).then( () => {
                  this.$emit('change', {'type': 'save'})
            })
        },
        deleteItem: function(event) {
            if (event.index < 0) {
                throw new Error(`Error deleting item - index is bad`)
            } else {
              this.$store.dispatch(
                  `${[BudgetCategoryAction.DeleteMonthlyCategoryInstance]}`,
                  event.item
              ).then(
                  () => {
                    this.$emit('change', {'type': 'delete'})
                  }
              )
            }
        },
    },
    computed: {
        defaultItem() {
            return {
                name: "",
                amount: 0,
                income: this.income,
            }
        },
        finpicProfile() {
          return this.$store.getters[`${[ProfileGetter.getFinpicProfile]}`]
        },
        activeFinpic() {
          return this.$store.getters[`${[ProfileGetter.getActiveFinpic]}`]
        },
    },
    props: {
        value: Array,
        title: String,
        income: Boolean,
        instanceid: Number,
        finpicid: Number,
        budgetid: Number,
    },
}
</script>
