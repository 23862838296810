<template>
    <div class="other-category">
        <v-row style="padding-left:20px">
            <h1>Setup Other Categories</h1>
        </v-row>
        <v-row style="padding-left:20px">
            <h3>Savings, unbudgeted expenses, etc.</h3>
        </v-row>
        <v-row style="padding-right:20px">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
            >
                Save
            </v-btn>
        </v-row>
        <br>
        <v-divider></v-divider>
        <br>
        <v-row justify="center">
            <div style="text-align:center">
            Add general categories here. They help you organize <br> unbudgeted
            expenses, track savings, or <br> coordinate reimbursed purchases.
            </div>
        </v-row>
        <v-row>
            <v-col cols="12">
                <CategoryInputList
                    v-model="savingCategories"
                    title="General Categories"
                    :income="false"
                    :instanceid="globalInstance.instanceid"
                    :finpicid="activeFinpic.finpicid"
                    :budgetid="savingBudget.budgetid"
                    @change="refreshCategories"
                >
                </CategoryInputList>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import CategoryInputList from '@/components/CategoryInputList.vue'
import {Action, Getter} from '@/store/budgetCategory/types'
import {Action as ProfileAction, Getter as ProfileGetter} from '@/store/profile/types'
export default {
  name: "OtherCategory",
  metaInfo: {
    title: 'Saving',
  },
  data: () => ({
    category_data: [
      {
        "Name": "Savings",
        "Amount": 0,
      }
    ],
  }),
  components: {
      CategoryInputList
  },
  computed: {
    globalInstance() {
      return this.$store.getters[`${[Getter.GetGlobalInstance]}`]
    },
    savingCategories() {
      return this.$store.getters[`${[Getter.GetSavingCategories]}`]
    },
    savingBudget() {
      return this.$store.getters[`${[Getter.GetSavingBudget]}`]
    },
    activeFinpic() {
      let item = this.$store.getters[`${[ProfileGetter.getActiveFinpic]}`]
      console.log(item)
      return item
    },
    finpicProfileAlt() {
      return this.$store.getters[`${[ProfileGetter.getFinpicProfileAlt]}`]
    },
  },
  mounted() {
    this.$store.dispatch(`${[ProfileAction.loadUserProfile]}`)
    this.$store.dispatch(`${[Action.FetchGlobalInstance]}`)
    this.$store.dispatch(`${[Action.FetchSavingCategories]}`)
    this.$store.dispatch(`${[Action.FetchSavingBudget]}`)
  },
  methods: {
    refreshCategories: function() {
      this.$store.dispatch(`${[Action.FetchSavingCategories]}`)
    }
  },
}
</script>
