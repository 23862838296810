<template>
    <div>
        <v-data-table 
            :items="value" 
            :headers="headers" 
            dense 
            disable-sort
            hide-default-footer 
            disable-pagination
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>{{ title }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                Add
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                                <slot name="form" v-bind:editedItem="editedItem">
                                    This is a form for input or editing
                                </slot>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="close">
                                    Cancel
                                </v-btn>
                                <v-btn color="primary" text @click="save">
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="headline">
                                <slot name="deleteTitle" v-bind:editedItem="editedItem">
                                    Are you sure you want to delete this {{ itemName }}?
                                </slot>
                            </v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="closeDelete">Cancel</v-btn>
                                <v-btn color="primary" text @click="deleteItemConfirm">Yes</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import _ from "lodash"
export default {
    name: "DataTableCRUD",
    data: () => ({
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {},
    }),
    props: {
        value: Array,
        title: String,
        headers: Array,
        defaultItem: Object,
        itemName: String,
    },

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New ' + this.itemName : 'Edit ' + this.itemName
        }
    },

    created() {
        this.initialize()
    },

    watch: {
        dialog: function(val) {
            val || this.close()
        },
        dialogDelete: function(val) {
            val || this.closeDelete()
        },
    },

    methods: {
        initialize: function() {
            this.editedItem = _.cloneDeep(this.defaultItem);
        },
        editItem(item) {
            this.editedIndex = this.value.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        deleteItem(item) {
            this.editedIndex = this.value.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },
        deleteItemConfirm() {
            this.$emit(
                'deleteItem', 
                {index: this.editedIndex, item: this.editedItem},
            )
            this.closeDelete()
        },
        close() {
            // why does this fire twice...?
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = _.cloneDeep(this.defaultItem)
                this.editedIndex = -1
            })
        },
        closeDelete() {
            // why does this fire twice...?
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = _.cloneDeep(this.defaultItem)
                this.editedIndex = -1
            })
        },
        save() {
            this.$emit(
                'saveItem',
                {index: this.editedIndex, item: this.editedItem},
            )
            this.close()
        },
    }
}
</script>
